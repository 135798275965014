/* eslint-disable */
import {Pagination, Autoplay, Navigation} from 'swiper/modules';
import {Swiper} from 'swiper'
import 'swiper/css';

Swiper.use([Pagination, Autoplay, Navigation])

window.addEventListener('load', () => {
  heroSliderBlock.init();
});

const heroSliderBlock = {
  init: function () {
    const sections = document.querySelectorAll('[data-partners-block-js]');

    if (!sections.length) return;

    sections.forEach((section) => {
      this.initSection(section)
    })

  },

  initSection: function (section) {
    this.initSwiper(section);
    this.initLoadMore(section);
  },

  initSwiper: function (section) {
    let sliderEl = section.querySelector('[data-partners-slider-js]');
    if (!sliderEl) return;
    let swiperWrapper = sliderEl.querySelector('.swiper-wrapper');

    let swiper = new Swiper(sliderEl, {
      speed: 1500,
      spaceBetween: 8,
      observer: true,
      observeParents: true,
      grabCursor: true,
      slidesPerView: 'auto',
      loop: true,
      autoplay: {
        delay: 1000,
        disableOnInteraction: false,
      },
      breakpoints: {
        768: {
          spaceBetween: 16,
        },
      },
    });

    if(swiperWrapper){
      swiperWrapper.addEventListener('mouseenter', ()=> {
        swiper.autoplay.stop();
      });

      swiperWrapper.addEventListener('mouseleave', ()=> {
        swiper.autoplay.start();
      });
    }
  },

  initLoadMore: function (section) {
    let mainGrid = section.querySelector('[data-main-partners-grid-js]');
    let showAllBtn = section.querySelector('[data-show-all-js]');
    if (!mainGrid || !showAllBtn) return;

    let gridItems = mainGrid.querySelectorAll('.our-partners__grid-item');
    if (gridItems && gridItems.length <= 14) {
      section.classList.add('show-all');
    }

    showAllBtn.addEventListener('click', () => {
      section.classList.add('show-all');
      console.log('show all');
    })
  }
}

export default heroSliderBlock;
